@import '../../../assets/variables/variables';

$transition-time: 400ms;
$mobile-screen-width: 500px;

.layout {
  display: flex;
  overflow: hidden;
  height: 100vh;
  // background-color: #F5F7FA;
  background: linear-gradient(180deg, #506DF5 0%, #8550F5 100%);

  .leftSideContainer {
    width: 100%;
    max-width: 240px;
    // background-color: #fff;
    background: none;
    height: 100vh;
    overflow: hidden;
    transition: all ease $transition-time;

    &.sidebarClosed {
      max-width: 80px;
      transition: all ease $transition-time;
    }

    .logoContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // padding: 0 20px;
      padding: 18px 0 0;
      padding: 25px 0;

      .iconContainer {
        height: 86px;
        width: 135px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-size: 13px;
        margin: 0;
        padding: 0;
        font-weight: 700;
        letter-spacing: 0.9px;
        line-height: normal;
        color: rgba(255, 255, 255, 1);
      }

      .mainIconDiv {
        height: 40px;
        width: 40px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 50%;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .mainIconImage {
          height: 15px;
          width: 15px;
        }
      }

      .logo {
        width: 130px;
      }
    }

    .navContainer {
      padding: 10px;
      // height: calc(100% - 70px);
      // display: grid;
      grid-auto-rows: min-content;
      gap: 10px;
      // align-items: center;
      overflow: auto;
      // place-items: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      // max-height: calc(80vh - 86px);
      max-height: calc(80vh - 104px);
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background: #fff6;
      }

      &::-webkit-scrollbar-corner {
        background: none;
      }


      .portalNavLink {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        gap: 20px;
        font-size: 16px;
        // color: #333;
        padding: 0 10px;
        height: 35px;
        font-weight: 600;

        .icon {
          width: 15px;
          height: 15px;
        }


        &:hover {
          background-color: #dde0ffc0;
          color: #000000;
          font-weight: 600;
          border-radius: 8px;
        }
      }

      .portalLinkactive {
        background-color: #e4e6fd80;
        color: #000000;
        font-weight: 600;
        border-radius: 8px;
      }

      
      .activeLink {
        // background-color: $background-color;
        background-color: rgba(255, 255, 255, 0.2);
        // border: 1.5px solid rgba(255, 255, 255, 0.2);
        // color:#F0F1F9;
        font-weight: 500;
        border-radius: 8px;
        // width: 178px;
        // height: 40px;
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        position: relative;
        outline: 1.5px solid #fff6;

        svg {
          fill: rgba(255, 255, 255, 1);
          font-size: 17px;
        }

        i {
          display: block;
          // margin-left: 75px;
          position: absolute;
          top: 13px;
          right: 10px;
        }
      }

    }
    .navLink {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      gap: 20px;
      font-size: 14px;
      padding: 9px 10px;
      // height: 35px;
      animation: pop-in 100ms ease-out;
      width: 178px;
      // width: 16px;
      // width: 80%;
      // height: 40px;
      font-size: 12px;
      color: rgba(255, 255, 255, 1);
      font-weight: 500;
      transition: all ease $transition-time;

      &.sidebarClosed {
        width: 16px;
        transition: all ease $transition-time;
      }

      svg {
        fill: rgba(255, 255, 255, 1);
        font-size: 17px;
      }

      i {
        display: none;
      }

      .icon {
        width: 15px;
      }

      // &:hover {
      //   background-color: #dde0ffc0;
      //   color: #000000;
      //   font-weight: 500;
      // border-radius: 12px;
      // }
    }

    .logoutButton {
      margin-left: 20px;
    }
    
    .linksNames {
      font-family: 'Poppins', sans-serif;
      display: flex;
      align-items: center;
      justify-content: space-around;
      // height: 24px;
      color: rgba(255, 255, 255, 1);

      .icon {
        height: 20px;
        object-fit: contain;
      }
      .instructIcon{
        height: 20px;
        width: 20px;
        margin-left: -2px;
      }

      .linkPageName {
        margin: 0;
        margin-left: 26px;
        font-size: 11px;
        font-weight: 500;
        // display: none;
        height: 15px;
        overflow: hidden;
      }
    }
  }
  
  .notificationModalContainer{
    position: absolute;
    background-color: white;
    overflow-wrap: break-word;
    border-radius: 5px;
    padding: 20px;
    height: auto;
    box-shadow: 0 0 3px #999;
    width: 200px;
    top: 35px;
    right: 0;
    z-index: 10;
    font-size: 13px;
  }
  .rightSideContainer {
    width: 100%;
    max-width: calc(100vw - 165px);
    padding: 20px 30px;
    margin: 13px;
    background: #F7F8FC;
    border-radius: 20px;

    .topBarContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // overflow: auto;
      gap: 20px;
      font-weight: 400;
      height: 35px;

      .leftSide {
        display: flex;
        align-items: center;
        gap: 10px;

        .backIcon {
          cursor: pointer;
        }

        .pageName {
          font-size: 20px;
          font-weight: 600;
          text-transform: capitalize;
        }
      }

      .rightSide {
        display: flex;
        position: relative;
        gap: 20px;
        
        .notificationIconContainer {
          position: relative;
          display: flex;

          .notificationIcon {
            width: 18px;
            cursor: pointer;
          }


          .unread {
            position: absolute;
            background-color: #CA3B3B;
            top: 4px;
            right: 0;
            width: 8px;
            border-radius: 4px;
            aspect-ratio: 1;
          }
        }

        .logoutCircle {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #525252;
          position: relative;
          
          .logoutModal {
            position: absolute;
            top: 35px;
            right: 0;
            padding: 10px;
            box-shadow: 0 0 3px #999;
            border-radius: 5px;
            font-size: 12px;
            z-index: 5;
            background: #fff;
            cursor: pointer;
            user-select: none;
          }

          .moreIcon {
            width: 16px;
            cursor: pointer;
          }

          .userName {
            color: #0B0E1D;
            font-size: 11px;
            font-weight: 600;
          }

          .profilePic {
            width: 30px;
            aspect-ratio: 1;
            border-radius: 15px;
          }
          
          .firstLetter {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            border: 1px solid #4519c5;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            font-weight: 500;
            cursor: pointer;
            user-select: none;
            // color: white;
            color: #4519c5;
            border-radius: 50%;
            font-size: 25px;
            // padding: 2px 12px;
            margin: 0px;
          }
        }
        
        .logoutDiv {
          border: 1px solid #bfbfbf;
          border-radius: 4px;
          font-size: 15px;
          cursor: pointer;
          display: flex;
          gap: 6px;
          align-items: center;
          
          .userEmail {
            padding: 9px 10px;
            border-right: 1px solid #bfbfbf;
            min-width: 200px;
          }

          .icon {
            width: 10px;
            height: 7px;
            padding-right: 12px;
            padding-left: 6px;
            margin-top: 6px;
          }
        }

        .generate {
          font-size: 15px !important;
          padding: 9px 19px;
        }
      }
    }

    .outletDiv {
      padding: 10px 0;
      width: calc(100vw - 165px);
      transition: width ease 300ms;

      &.sidebarOpen {
        width: calc(100vw - 326px);
        transition: width ease 300ms;
      }
    }

    .logoutButton {
      border: 1px solid #878585 !important;
      padding: 2px;
      position: absolute;
      top: 65px;
      display: flex;
      width: 120px;
      z-index: 99;
      right: 19px;
      background: white;
      color: #131313;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      align-items: center;
      justify-content: center;
      gap: 6px;
      border-radius: 5px;

      .logoutIcon {
        width: 20px;
        height: 20px;
      }

      .logoutBtn {
        // border: 1px solid #878585 !important;
        // position: absolute;
        // top: 60px;
        // width: 254px;
        // z-index: 99;
        // right: 19px;
        // background: white;
        // color: #131313;
        // background-color: white;

        // padding: 10px 0px;
        // border: 1px solid #878585 !important;
        // position: absolute;
        // top: 74px;
        // width: 120px;
        // z-index: 99;
        // right: 19px;
        // background: white;
        // color: #131313;
        // background-color: white;
        // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        border: none !important;
        color: black;
        font-size: 15px !important;
        font-weight: 600;

        // &:hover {
        //   background-color: rgb(235, 232, 232);
        // }
      }
    }
  }
}

@media screen and (min-width: $mobile-screen-width) {
  .menuIcon {
    display: none;
    &.sidebarOpen {

    }
  }
}

@media screen and (max-width: $mobile-screen-width) {
  .leftSideContainer {
    width: 0 !important;
    // transition: width ease 400ms;

    &.sidebarOpen {
      position: fixed !important;
      z-index: 100;
      box-shadow: 5px 5px 5px #3339;
      background: linear-gradient(273deg, #506DF5 0%, #8550F5 100%);
      width: 100vw !important;
      // transition: width ease 400ms;
    }
  }

  .rightSideContainer {
    max-width: calc(100vw - 57px) !important;
    padding: 16px !important;
    // background-color: red !important;

    .sidebarOpen {

    }

    .outletDiv {
      width: 100% !important;
    }
  }

  .pageName {
    font-size: 13px !important;
  }

  .menuIcon {
    display: block;
    color: white;
    background: linear-gradient(273deg, #506DF5 0%, #8550F5 100%);
    border-radius: 8px;
    width: 20px;
    padding: 2px 5px;
  }
  .userName {
    // background: red !important;
    display: none;
  }
}


@keyframes pop-in {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  50% {
    opacity: 0.33;
    transform: translateY(50%);
  }
}

.instructionContainer{
  width: 150px;
  height: 100px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .instructionContent{
    margin-left: 30px;
    display: flex;
    gap: 13px;
    .instructionIcon{
      margin-top: 2.5px;
      height: 11px;
      width: 11px;
    }
    .instructionText{
      cursor: pointer;
      font-size: 11px;
      font-weight: 200;
    }
  }
}

.viewManual {
  :global {
      .modalBody {
          max-height: 85vh !important;
      }
  }
  width: 60vw;
  max-height: 90vh !important;

  iframe, video {
      height: 75vh;
      width: 100%;
  }
}

.activeContent{
  background-color: rgba(255, 255, 255, 0.2);
    font-weight: 500;
    border-radius: 8px;
    font-size: 12px;
    color: rgb(255, 255, 255);
    position: relative;
    outline: 1.5px solid rgba(255, 255, 255, 0.4);
}