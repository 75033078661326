@import "assets/scss/crudPage";

.userDetailsHeader {
    display: flex;
    font-size: 11px;
    height: 63px;
    align-items: center;
    padding: 10px 20px;
    text-align: left;
    gap: 50px;

    .container {
        display: flex;
        flex-direction: column;

        .heading {
            text-transform: capitalize;
            padding: 2.5px;
        }
        
        .value {
            padding: 2.5px;
            font-weight: 600;
        }
    }
}

.filterButton {
    // background: red !important;
    height: 32px;
}

.approveButton {
    height: 32px;
    background: #15A54F !important;

    &:hover {
        background: #15A54F !important;
    }
}

.rejectButton {
    height: 32px;
    background: #E32626 !important;

    &:hover {
        background: #E32626 !important;
    }
}

.checkbox {
    cursor: pointer;
}