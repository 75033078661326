.mainContainer{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color:rgba(0, 0, 0, 0.5) ;
    backdrop-filter: blur(4px);
    z-index: 5;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
    
    .modalDiv{
        min-width: 320px;
        height:  fit-content;
        background-color: #fff;
        border-radius: 28px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border: solid 4px #E9EAF9;
        animation: modaldivAnimation 0.5s;
        // animation-fill-mode: both;

        .modalHeader{
            border-bottom: solid 1px #F2F2F2;
            // padding: 9px 0;
            p{
                font-size: 20px;
                font-weight: 600;
                margin: 0;
                padding: 15px;
            }
        }

        .modalBody {
            margin: 15px;
            max-height: 60vh;
            overflow-y: auto;
        }
    }
}

@keyframes modaldivAnimation {
    0%{
       opacity: 0;
    }
    100%{
    opacity: 1;
    }
}