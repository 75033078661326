.form {
    text-align: left;
    padding: 20px;

    .buttonsContainer {
        display: grid;
        // justify-content: space-between;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        padding: 10px 0;
        // height: 30px;

        .cancelButton {
            background: none !important;
            color: #8450F5;
            // background: #eee !important;
            border: 1px solid #8450F5;
    
            &:hover {
                background: #8450F511 !important;
            }
        }
    }
}