
.button{
    font-size: 12px !important;
    color: white;
    border-radius: 5px;
    padding: 7px 15px;
    cursor: pointer;
    border: none;
    background: linear-gradient(273deg, #506DF5 0%, #8550F5 100%);
    user-select: none;

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;

        &:hover {
            opacity: 0.6;
        }
    }
}

.outlineButton{
    border: 1px solid #572FCA !important;
    color: #572FCA;
    background-color: #fff;
    &:hover{
        border-color: #704ae4;
        opacity: 0.9;
    }
    &:active{
        background-color: #bfbfbf !important;
        opacity: 1;
    }
}

.primaryButton{
    background-color: #4519C5 !important;

    &:hover{
        background-color: #572FCA !important;
        opacity: 0.9;
    }
    &:active{
        background-color: #572FCA !important;
        opacity: 1;
    }
}