.cardContainer {
    display: flex;
    // background-color: red;
    justify-content: space-between;
    padding: 10px;
    
    .card {
        display: flex;
        width: 100px;
        height: 50px;
        background-color: #506DF522;
        border: 2.5px solid #506DF544;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: #506DF53c;
        }
    }
}