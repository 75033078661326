.view {
    width: 500px;
    height: 59vh;
    object-fit: contain;
    background: #0002;
    border-radius: 5px;
    flex-shrink: 0;
}

.spinnerDiv {
    // width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.multipleReceiptsContainer {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 10px;
    overflow-x: auto;

    &::-webkit-scrollbar:horizontal {
        height: 10px;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        border-radius: 5px;
    }
}

.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span:first-child {
        text-transform: capitalize;
    }
}

.description {
    margin: 6px;
    text-align: left;

    span {
        font-weight: 400;
    }
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    font-size: 16px;
}
 
//////

.modalDiv {
    :global {
        .modalBody {
            max-height: 80vh !important;
        }
    }

    .confirmDiv {
        // padding: 12px 10px;
        // margin: 16px;
        margin-bottom: 0;
        border-radius: 12px;
        // background: #DDDDDD;
        font-size: 14px;

        .buttons {
            display: flex;
            justify-content: flex-end;
            justify-content: center;
            gap: 16px;
            margin-top: 10px; 
        }
    }

    .propList {
        padding-left: 15px;
        text-align: left;
        margin: 0;
        
        li {
            margin: 10px 0 !important;

            span {
                padding: 10px 0;
                font-weight: 600;
            }
        }
    }

    .allFieldsContainer {
        // height: 63vh !important;
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 500px;
        padding: 20px;
        padding-top: 0;
        // height: 53vh;
        max-height: 63vh;
        overflow-y: auto;
        margin-top: 16px;
    
        .label {
            margin: 5px 0;
            font-weight: 600;
        }

        .data {
           margin: 0;
           font-size: 14px; 
        }
    }
}

@media screen and (max-width: 500px) {
    .view,.spinnerDiv {
        width: 80vw !important;
    }
}