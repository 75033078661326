.mainContainer {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: url("../../../assets/png/login-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    // display: flex;
    // justify-content: flex-end;

    .childrenContainer {
        position: absolute;
        top: 50vh;
        right: 8.3vw;
        transform: translateY(-50%);
        border: 1px solid #DCDCDC;
        background: #FFF;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
        padding: 25px 20px;
        border-radius: 20px;
    }

    .wrapTriangle {
        position: absolute;
        right: 0;
        filter: drop-shadow(0px -4px 32px rgba(0, 0, 0, 0.40));

        .triangle {
            position: absolute;
            right: 0;
            z-index: 1;
            height: 100vh;
            width: 35vw;
            background: linear-gradient(273deg, #506DF5 0%, #8550F5 98.81%);
            clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
            // box-shadow: 0px -4px 32px rgba(0, 0, 0, 0.40);
        }
    }

}