@import "assets/scss/crudPage";

.name {
    text-transform: capitalize;
}

.exceeded {
    color: orangered;
}

.warning {
    color: rgb(228, 228, 14);
}

.eyeIcon {
    align-self: center;
    fill: #797979;
    cursor: pointer;
}

.uploadIcon {
    fill: white;
    stroke: none;
    position: absolute;
    height: 50%;
    top: 7.5px;
    width: 20px;
    left: 8px;
}

.uploadFileButton {
    position: relative;
    padding-left: 35px;
}

.instruction {
    border-radius: 50% !important;
    border-radius: 5px;
    border: solid;
    height: 35px;
    width: 35px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    color: white;
    font-size: 13px;
    background-color: #ffffff;
    box-shadow: 0 0 3px #aaa;
    position: relative;
    transition: all ease 300ms;
    user-select: none;

    &:first-child {
        left: 100px;
    }

    &.open {
        left: 0px;
        transition: all ease 200ms;
    }

    &.noVisible {
        opacity: 0;
        visibility: hidden;

        img {
            visibility: hidden;
        }
    }

    img, svg {
        height: 18px;
        transition: all 0.4s !important;
        width: 18px;
    }

    i {
        color: black;
        font-size: 20px;
        animation: mymove 0.6s;
    }
}

.viewManual {
    :global {
        .modalBody {
            max-height: 85vh !important;
        }
    }
    width: 60vw;
    max-height: 90vh !important;

    iframe, video {
        height: 75vh;
        width: 100%;
    }
}

.downloadContainer {
    position: relative;
    .downloadButtonStyles{
        height: 34px;
    }
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    z-index: 1000;
    width: fit-content;

    hr {
        margin: -3px 0;
        border: 0.1px solid #fff5f5; 
      }
}

.dropdown button {
    
    padding: 10px 20px;
    width: 100%;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
    color: #333;
    transition: background 0.3s;

    &:hover {
        background-color: #f0f0f0;
    }
}

.actionIcon {
    height: 15px;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .instruction:first-child {
        left: 0px;
    }

    .viewManual {
        width: 90vw;
    }
}
