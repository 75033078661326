.view {
    width: 500px;
    height: 59vh;
    object-fit: contain;
    background: #0002;
    border-radius: 5px;
}

.spinnerDiv {
    width: 500px;
    height: 59vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.multipleReceiptsContainer {
    height: 100%;
}

.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span:first-child {
        text-transform: capitalize;
    }
}

.description {
    margin: 6px;
    text-align: left;

    span {
        font-weight: 400;
    }
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    font-size: 16px;
}

@media screen and (max-width: 500px) {
    .view,.spinnerDiv {
        width: 80vw !important;
    }
}