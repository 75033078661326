@import "assets/scss/loginPage";

.selectedEmail {
    font-size: 12px;
    margin: 0;
    display: flex;
    gap: 10px;
    font-weight: 400;
}

.inputsContainer {
    display: grid;
    gap: 10px;
    max-width: 300px;
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    margin: 10px 0;
    padding: 10px 0;

    input {
        // aspect-ratio: 1;
        height: 40px;
        border: 0.868px solid #9A9A9A9A;
        border-radius: 4px;
        font-size: 16px;
        padding: 0 15px;

        &:focus {
            outline: 1px solid #9A9A9A;
        }
    }
}

.backIcon {
    margin-left: -6px;
    cursor: pointer;
}

.subHeading {
    display: flex;
    gap: 8px;

    .verifiedIcon {
        width: 12px;
    }
}

.passwordNotes {
    padding: 10px 0;
    font-size: 11px;
    color: #999;
    display: flex;
    flex-direction: column;

    ul {
        margin: 0;
        font-size: 10px;
    }
}