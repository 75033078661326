@import "assets/variables/_variables";

$mobile-screen-width: 500px;

.table {
  width: calc(100vw - 165px) !important;
  // width: max-content !important;
  max-height: calc(100vh - 140px);
  // border-bottom: 1px solid #eeeeee;
  border-radius: 0 0 10px 10px !important;
  overflow: auto !important;
  transition: all ease 300ms;
  background: #fff;

  &.sidebarOpen {
    width: calc(100vw - 326px) !important;
  }

  &.moreWidth::-webkit-scrollbar {
    height: 10px;
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    // background: white;
    border-radius: 0 0 5px 0px;
    transition: all ease 500ms;
  }

  &::-webkit-scrollbar-track:vertical {
    background: white;
    border-radius: 0 0 15px 5px;
  }

  &::-webkit-scrollbar-corner {
    background: none;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #572fca;
    border-radius: 5px;
  }

  :global {
    .rdt_Table {
      background: none;
    }

    .rdt_TableHeadRow {
      color: #0B0E1D;
      font-size: 11px;
      border-bottom: 1px solid rgba(241, 243, 249, 0.50);
      font-weight: 600;
      padding: 0 8px;
    }

    .rdt_TableRow {
      border-bottom: 1px solid rgba(241, 243, 249, 0.50) !important;
      font-size: 11px;
      color: $tableBodyTextColor;
      padding: 0 8px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .rdt_TableRow:last-child {
      // padding-bottom: 10px;
      border-bottom: 1px solid #eeeeee;
      // border-radius: 0 0 16px 16px !important;
    }

    .rdt_TableHead {
      z-index: 3;
      padding: 0px !important;
    }

    .rdt_TableBody {
      // max-height: calc(100vh - 281px);
      // padding-bottom: 10px;
      // border-bottom: 1px solid #eeeeee;
      border-radius: 0 0 10px 10px !important;
      // overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: #572fca;
        border-radius: 3px;
      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  position: absolute;
  width: calc(100vw - 165px);
  bottom: 20px;
  transition: all ease 300ms;
  // height: 70px;

  &.sidebarOpen {
    width: calc(100vw - 326px);
    transition: all ease 300ms;
  }

  .dataRange {
    font-size: 12px;
    font-weight: 600;
  }

  .paginationRightSide {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 10px;

    .dropdownText {
      font-size: 14px;
    }
    .rowPerPageDropdown {
      font-size: 14px;
      border: none;
      border-bottom: 1px solid #9a9a9a;
      outline: none;
      margin-top: 4px;
      width: 50px;
      cursor: pointer;
    }

    .paginationContainer {
      width: fit-content;
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 5px;
      margin-left: 15px;
      font-size: 12px;

      .arrowBox {
        width: 25px;
        height: 25px;
        background: #D4DAED;
        display: flex;
        justify-content: center;
        align-items: center !important;
        border-radius: 5px;
        cursor: pointer;

        .arrow {
          width: 8px;
          aspect-ratio: 2;
          margin-bottom: 2px;
        }

        .previousArrow {
          transform: rotate(90deg);
        }
        .nextArrow {
          transform: rotate(-90deg);
        }
      }

      .page {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .activePage {
        background-color: #8450F5;
        color: #fff;
        border-radius: 5px;
      }

      .disabled {
        opacity: 0.5;
      }
    }
  }
}

@media screen and (max-width: $mobile-screen-width) {
  .table {
    width: calc(100vw - 57px) !important;
    max-height: calc(100vh - 86px);

    &.sidebarOpen {
      width: calc(100vw - 53px) !important;
    }

    :global {
      .rdt_TableBody {
        max-height: calc(100vh - 425px);
      }
    }
  }

  .noData {
    padding: 10px 0;
    font-size: 12px;
  }

  .disabled {
    opacity: 0.5; // Adjust the opacity to make the button appear disabled
    cursor: not-allowed; // Change the cursor to indicate that the button is not clickable
}

  .pagination {
    bottom: 1vh !important;
    position: fixed !important;
    flex-direction: column !important;
    align-items: flex-end;
    z-index: 1;
    width: 85%;
  }
}