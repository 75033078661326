/* element selectors */
body {
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 0 0 5px 5px;
}

::-webkit-scrollbar-corner {
  background: none;
}

::-webkit-scrollbar-thumb {
  background-color: #572fca;
  border-radius: 3px;
  cursor: pointer !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* class selectors */
.validation-error-text {
  color: orangered !important;
  font-size: 10px;
  margin: 0;
}

.required-mark {
  color: orangered;
}

.status-button {
  padding: 5px 10px;
  outline: none;
  border: none;
  border-radius: 5px;
}

.link-text {
  color: #4a13c0;
  font-weight: 600 !important;
  cursor: pointer;
}

.right-alignment {
  text-align: right;
}
.right-alignment-col {
    display: flex;
    justify-content: end;
    width: 100%;
}
.right-alignment-acc{
    text-align-last: end;
}
.cursor-auto{
    cursor: auto !important;
}
.table {
  .rdt_Table {
    .rdt_TableBody {
      .rdt_TableRow {
        .rdt_TableCell {
          div {
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
}
/* embed::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 0 0 5px 5px;
}

embed::-webkit-scrollbar-corner {
    background: none;
}
  
embed::-webkit-scrollbar-thumb {
    background-color: #572fca;
    border-radius: 3px;
} */
