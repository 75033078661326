.buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    gap: 10px;

    .deleteButton {
        background: rgb(225, 71, 71) !important;
    }

    .cancelButton {
        background: none !important;
        color: #333;
        background: #eee !important;

        &:hover {
            background: #dddd !important;
        }
    }
}