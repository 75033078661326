@import "assets/scss/crudModal";

.form {
    .reasonTextarea {
        height: 150px !important;
        
        textarea {
            width: 300px !important;
            height: 100px !important;
            resize: none;
        }
    }
}