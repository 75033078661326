$mobile-screen-width: 500px;

.notificationModalContainer {
    position: absolute;
    background-color: white;
    overflow-wrap: break-word;
    border-radius: 5px;
    // padding: 10px 0;
    height: auto;
    box-shadow: 0 0 3px #999;
    width: 400px;
    top: 35px;
    right: 0;
    z-index: 10;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    // gap: 5px;
    max-height: 250px;
    overflow-y: auto;

    .noNotifications {
        padding: 20px;
    }

    .notification {
        text-align: left;
        margin: 0;
        // background: #F7F8FC;
        background: white;
        padding: 12px 10px;
        border-bottom: 0.5px solid #9993;

        &:last-child {
            border-bottom: none;
        }
    }
    // max-width: ;
}

@media screen and (max-width: $mobile-screen-width) {
    .notificationModalContainer {
        width: 70vw;
    }
}