
.loadingText {
    font-family: "Poppins" !important;
    font-size: 10px !important;
    font-weight: 600 !important;
    margin: 0 !important;
    span {
        opacity: 0;
        font-size: 10px !important;
    }
    
    .dotOne {
        animation: dotOne 1s infinite linear
    }
    
    .dotTwo {
        animation: dotTwo 1s infinite linear
    }
    
    .dotThree {
        animation: dotThree 1s infinite linear
    }
}


@keyframes dotOne {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes dotTwo {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes dotThree {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}