@import "assets/variables/variables";

.fileUploadDiv {
    line-height: 10px;
    // padding: 10px;
    box-sizing: border-box;
    width: 300px;
    padding: 0 16px;
    text-align: left;

    .fileNamesDiv {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        margin: 10px 0 5px 0;
        max-height: 50px;
        overflow-y: auto;

        .fileName {
            padding: 2px 5px;
            font-size: 10px;
            background: #8450F533;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            width: 60%;
            
            .crossIcon {
                color: red;
                height: 15px;
                cursor: pointer;
            }
        }
    }

    .uploadInputContainer {
        margin-top: 5px;
        padding-top: 16px;
        border: 3px dashed #8450F5;
        border-radius: 12px;
        margin-bottom: 20px;
        text-align: center;
    }

    .label {
        font-size: 12px;
        font-weight: 400;

        .requiredMark {
            color: orangered;
        }
    }
    // .inputControl {
    //     display: flex;
    //     flex-direction: column;
    //     height: 60px;
    //     gap: 3px;
    //     text-align: left;

    //     .descErr {
    //         color: orangered;
    //         font-size: 11px;
    //     }
    
    //     .inputField {
    //         height: 30px;
    //         background: #FCFCFC;
    //         border: 0.868px solid #9A9A9A77;
    //         color: #000;
    //         font-weight: 600;
    //         border-radius: 5px;
    //         margin: 2px 0;
    //         box-sizing: border-box;
    //         padding: 0 10px;
    //         font-size: 10px;
    //         font-family: "Poppins";
    
    //         &::placeholder {
    //             color: #797979;
    //             font-weight: 400;
    //         }
            
    //         &:hover {
    //             border-color: #9A9A9Abb;
    //         }
    
    //         &:focus {
    //             border-color: #506DF555;
    //             outline: 2px solid #506DF555;
    //         }
    //     }
    // }

    .fileUploadMessage {
        color: #438501;
        font-style: italic;
        font-weight: 500;
        display: block;
        margin-bottom: 15px;
    }

    .fileUploadLogoImage {
        height: 60px;
        width: 65px;
        fill: url(#paint0_linear_334_1378);
    }

    .dragDropText {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        overflow-wrap: break-word;
        padding: 0 5px;
    }

    .infoText {
        color: #60627c;
        font-size: 12px;
        font-weight: 400;
        display: block;
    }

    span {
        font-size: 13px;
    }

    .fieldDiv {
        // border: solid 1px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 16px;

        .hideInput {
            display: none;
        }
    }

    .fileDiv {
        .fileUploadField {
            color: #ce2a48 !important;
        }

        .fileUploadInput {
            background-color: transparent;
        }

        .browseFileBtn {
            font-family: "Poppins";
            background-color: transparent;
            width: 114px;
            padding: 12px 15px;
            border: solid 2px;
            color: #8450F5;
            border-radius: 5px;
            font-size: 10px;
            font-weight: 600;
            cursor: pointer;
        }
    }

    .buttonsContainer {
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
        margin-bottom: 16px;
        margin-top: -10px;
        // align-items: center;
    }

    .uploadFileBtn {
        border: none;
        border-radius: 8px;
        background-color: transparent;
        font-family: "Poppins";
        font-size: 10px;
        font-weight: 600;
        background: $background-color;
        color: $whiteColor;
        width: 114px;
        padding: 13px 18px;
        cursor: pointer;

        &.disabled {
            cursor: default;
            opacity: 0.8;
        }
    }

    .cancelButton {
        font-size: 11px;
        width: 114px;
        // padding: 13px 18px 0;
        font-family: "Poppins";
        cursor: pointer;
        background: none;
        border: none;
        border-radius: 8px;
        color: #60627C;
    }

    .fileLimitText {
        font-size: 10px;
        font-weight: 400;
        margin-bottom: 13px;
    }
}

.customfileinput {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    z-index: -1;
}

.orText {
    text-align: center;
    margin: 3px;

    &.hide {
        visibility: hidden;
    }
}

//   .customfilelabel {
//     color:$background-color;
//     font-weight: 600;
//     font-size: 11px;
//      width: 85px;
//     padding:13px 13px;
//     border-radius: 5px;
//     cursor: pointer;
//     display: inline-block;
//     border: solid 2px;
//   }

