.passwordNotes {
    position: absolute;
    z-index: 5;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 3px #999;
    padding: 10px;
    width: 200px;
    right: 0;
    font-size: 11px;
    color: #999;
    display: flex;
    flex-direction: column;

    ul {
        margin: 0;
        font-size: 10px;
    }
}