.pageHeader {
    // margin-bottom: 10px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(241, 243, 249, 0.50);
    padding: 0 24px;
    background: white;
    border-radius: 10px 10px 0 0;

    .headingRightSide {
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
        padding: 16px 0;
        min-height: 30px;

        .searchInput {
            border-radius: 5px;
            height: 32px;
            width: 125px;
            font-size: 11px;
            padding: 0 15px;
            border: 1px solid #D6D6D6;
            background: #F4F4F4;
            color: #0B0E1D;
            font-family: "Poppins";

            &::placeholder {
                color: #0B0E1D;
                font-weight: 400;
                font-family: "Poppins";
            }
    
            &:focus {
                outline: none;
            }
        }

        .filterButton {
            border-radius: 5px;
            border: 1.5px solid #DCDCDC;
            font-size: 11px;
            padding: 7px 15px;
            background: none;
            font-family: "Poppins";
            cursor: pointer;

            &:hover {
                background: #f9f9f999;
            }
        }

        .mainButton {
            font-family: "Poppins";
            color: #F0F1F9;
            font-size: 11px !important;
        }
    }
}

.name {
    color: #4A13C0;
    font-weight: 600;
    cursor: pointer;
}

.actions {
    display: flex;
    gap: 10px;

    .actionIcon {
        height: 15px;
        cursor: pointer;
    }
}

@media screen and (max-width: 500px) {
    .headingRightSide {
        // gap: 2px !important;

        .searchInput {
            width: 63vw !important;
        }
    }
}