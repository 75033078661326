.mainDiv {
    // border-radius: 20px;
    // border: 1px solid #DCDCDC;
    // background: #FFF;
    // box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
    padding: 0 15px;
    text-align: left;
    min-width: 24vw;
    width: max-content;
    max-height: 70vh;
    overflow-y: auto;

    .heading {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }

    .subHeading {
        color: #858585;
        font-size: 13px;
        font-weight: 400;
        margin: 0;
    }

    .form {
        padding-top: 20px;

        .button {
            font-size: 11px !important;
            font-weight: 600;
            font-family: "Poppins";
            width: 100%;
            height: 32px;
            border-radius: 5px;
            background: linear-gradient(273deg, #506DF5 0%, #8550F5 100%);
            // margin-bottom: 10px;
        }

        .bottomText {
            text-align: center;
            font-size: 11px;
            font-weight: 400;
        }
    }
}

@media screen and (max-width: 500px) {
    .mainDiv {
        width: 60vw !important;
    }
}